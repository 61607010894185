import { Controller } from "@hotwired/stimulus"
import flatpickr from 'flatpickr';
import rangePlugin from "flatpickr/dist/plugins/rangePlugin"
import { AlgerianArabic }  from "flatpickr/dist/l10n/ar-dz.js"
import { english }  from "flatpickr/dist/l10n/default.js"
import { French }  from "flatpickr/dist/l10n/fr.js"
import { Mandarin }  from "flatpickr/dist/l10n/zh.js"
import { Spanish }  from "flatpickr/dist/l10n/es.js"
import { Portuguese }  from "flatpickr/dist/l10n/pt.js"
import { German }  from "flatpickr/dist/l10n/de.js"
import { Japanese }  from "flatpickr/dist/l10n/ja.js"
import { Italian }  from "flatpickr/dist/l10n/it.js"
import { Turkish }  from "flatpickr/dist/l10n/tr.js"


// Connects to data-controller="search-activities"
export default class extends Controller {
  static targets = [ 
    "resultsContainer",
    "to", 
    "startDate", 
    "startDatePicker", 
    "mobileFlatpickr",
    "clearCalendarBtn"
  ]
  static values = {
    mostDestinations: Array,
    resultsContainer: String,
    locale: String,
    inline: Boolean,
    mode: String,
    showmonths: Number,
    mobile: Boolean
  }
  connect() {
    let controller = this
    // if (typeof (google) != "undefined") {
    //   this.initMap()
    // }

    // Date picker 
    controller.initDatePicker()

    // Destination picker
    controller.initResultsContainer()

    // Search function
    controller.initSearch()
  }

  params() {
    let params = new URLSearchParams()
    params.append('destination', this.toTarget.value)
    return params
  }

  async fetchDestinations() {
    let controller = this
    try {
      const response = await fetch(`/fetch_destinations?${this.params()}`, {
        headers: {  
          Accept: "application/json"  
        } 
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const destinationsData = await response.json();

      const resultWrapper = document.createElement('div');
      resultWrapper.classList.add("bg-light", "py-2", "h-100", "d-grid", "grid-columns-1", "gap-2", "overflow-auto")

      destinationsData.forEach(destination => {
        var destinationBtn = this.getdestinationHtmlTemplate(`${destination["name"]}, ${destination["state_name"]}`);
        destinationBtn.addEventListener('click', e => {
          controller.toTarget.value = `${destination["name"]}, ${destination["state_name"]}`
          controller.hideResultsContainer(controller, resultWrapper)
        })
        resultWrapper.appendChild(destinationBtn);    
      });
      controller.resultsContainerTarget.replaceChildren(resultWrapper)
      
    } catch (error) {
      console.error('Error fetching destinations:', error);
    }
  }

  initMap() {
    const options = {
      types: ['(regions)'],
      //types: ['(cities)'],
      componentRestrictions: {
        country: ["dz"],
      }
    };

    const autocomplete = new google.maps.places.Autocomplete(this.toTarget, options);
    
    autocomplete.addListener("place_changed", () => {
      let fetchData = {
        headers: new Headers({
          'Content-Type': 'application/json; charset=UTF-8'
        })
      }
      const place = autocomplete.getPlace();
      this.toTarget.value = place.name
      //console.log(place)
    })
  }

  //destinationResultsContainer
  initResultsContainer() {
    let controller = this
    var styleClasses = []
    const destinations = controller.hasMostDestinationsValue ? controller.mostDestinationsValue : [];
    
    if (controller.hasMobileValue) {
      styleClasses = ["h-100", "d-grid", "grid-columns-2", "gap-2", "overflow-auto"]
    } else {
      styleClasses = ["bg-light", "py-2", "h-100", "d-grid", "grid-columns-2", "gap-2", "overflow-auto"]
    }
    controller.createResultsContainer(destinations, styleClasses)
  }

  //destinationCreateResultsContainer
  createResultsContainer(destinations, styleClasses) {
    let controller = this
    if (controller.hasToTarget && controller.hasResultsContainerTarget) {
      const inputHeight = 61;

      controller.toTarget.parentElement.style = "cursor: pointer;"
      controller.toTarget.style = "cursor: inherit;"
      
      controller.resultsContainerTarget.classList.add("bg-light", "rounded-1")
      controller.resultsContainerTarget.style = `height: calc(100% - ${inputHeight}px);`


      // Create and fill with most visited states in algeria and search-time
      let resultWrapper = document.createElement('div');
      styleClasses.forEach(styleClasse => {
        resultWrapper.classList.add(styleClasse)
      });
      
      destinations.forEach(destination => {
        var destinationBtn = controller.getdestinationHtmlTemplate(destination);
        destinationBtn.addEventListener('click', (e) => {
          controller.toTarget.value = destination
          controller.resultsContainerTarget.classList.add("d-none")
          controller.hideResultsContainer(controller)
        })
        resultWrapper.appendChild(destinationBtn);        
      });

      // click listener when show/hide results container
      document.addEventListener('mousedown', (e) => {
        //e.preventDefault()
        if (controller.toTarget.parentElement.contains(e.target)) {
          controller.showResultsContainer(controller, resultWrapper)
          controller.resultsContainerTarget.classList.remove("d-none")
        } else {
          controller.resultsContainerTarget.classList.add("d-none")
          controller.hideResultsContainer(controller)
        }
      });
      
      
    }
  }

  showResultsContainer(controller, resultWrapper) {
    if (!controller.resultsContainerTarget.classList.contains("show")) {
      if (controller.toTarget.value === "") {
        controller.resultsContainerTarget.replaceChildren(resultWrapper)
      } else {
        controller.fetchDestinations()
      }
      
      controller.resultsContainerTarget.classList.add("show")
      if (controller.hasMobileValue) {
        controller.toTarget.parentElement.classList.add("bg-light", "rounded", "border")
        controller.toTarget.parentElement.style = `height: 350px; position: static; z-index: 1;`        
      } else {
        controller.toTarget.parentElement.classList.add("bg-light", "shadow-lg")
        controller.toTarget.parentElement.style = `height: 350px; position: absolute; z-index: 1;`
      }
      controller.toTarget.focus()
    }
    if (controller.hasMobileFlatpickrTarget && controller.hasMobileValue) {
      controller.mobileFlatpickrTarget.classList.add("d-none")
    }
  }

  hideResultsContainer(controller) {
    if (controller.resultsContainerTarget.children.length > 0 && controller.resultsContainerTarget.classList.contains("show")) {
      controller.resultsContainerTarget.classList.remove("show")
      controller.resultsContainerTarget.replaceChildren('')
      controller.toTarget.parentElement.classList.remove("bg-light", "shadow-lg")
      controller.toTarget.parentElement.style = "cursor: inherit;"
    }
    if (controller.hasMobileFlatpickrTarget && controller.hasMobileValue) {
      controller.mobileFlatpickrTarget.classList.remove("d-none")
    }
  }

  initSearch() {
    let controller = this
    if (controller.hasToTarget && controller.hasResultsContainerValue) {
      controller.toTarget.addEventListener('input', (e) => {
        let timer;
        clearTimeout(timer);
        console.clear()
        timer = setTimeout(() => { 
          if (e.target.value === "") {
            controller.resultsContainerTarget.classList.add("d-none")
            controller.hideResultsContainer(controller)
            if (controller.hasMobileFlatpickrTarget && controller.hasMobileValue) {
              controller.mobileFlatpickrTarget.classList.remove("d-none")
            }
          } else {
            if (!controller.resultsContainerTarget.classList.contains("show")) {
              controller.initResultsContainer()
              controller.resultsContainerTarget.classList.remove("d-none")
              controller.resultsContainerTarget.classList.add("show")
              if (controller.hasMobileValue) {
                controller.toTarget.parentElement.classList.add("bg-light", "rounded", "border")
                controller.toTarget.parentElement.style = `height: 350px; position: static; z-index: 1;`        
              } else {
                controller.toTarget.parentElement.classList.add("bg-light", "shadow-lg")
                controller.toTarget.parentElement.style = `height: 350px; position: absolute; z-index: 1;`
              }
              if (controller.hasMobileFlatpickrTarget && controller.hasMobileValue) {
                controller.mobileFlatpickrTarget.classList.add("d-none")
              }
            }
            controller.fetchDestinations()  
          }
          //controller.fetchDestinations()
        }, 0);
      })
    }
  }

  initDatePicker(){
    let calendar;
    let controller = this;
    let config = controller.getConfig()
    if (controller.hasMobileFlatpickrTarget) {
      config["appendTo"] = controller.mobileFlatpickrTarget
    }
    calendar = flatpickr(this.startDatePickerTarget, config);

    controller.clearCalendarBtnTarget.addEventListener("click", (e) => {
      controller.clearCalendar(calendar)
    })
  }

  clearCalendar(calendar) {
    calendar.clear();
    this.startDateTarget.value = ""
  }

  clearPlace() {
    this.toTarget.value = ""
  }

  getConfig() {
    let controller = this;
    return {
      "locale": controller.getLocale(this.localeValue),
      showMonths: controller.showmonthsValue,
      dateFormat: controller.getdateFormat(this.localeValue),
      mode: controller.modeValue,
      inline: controller.inlineValue,
      minDate: "today",
      disableMobile: true,
      nextArrow: "<i class='fi-chevrons-right fs-7'></i>",
      prevArrow: "<i class='fi-chevrons-left fs-7'></i>",
      onClose: function(selectedDates, dateStr, instance) {
        controller.startDateTarget.value = selectedDates[0].toString()
      },
    }
  }

  getLocale(locale) {
    // :ar, :en, :fr, :cn, :es, :pt, :de, :ja, :it, :tr
    if (locale == "ar") {
      return AlgerianArabic
    }

    if (locale == "en") {
      return english
    }

    if (locale == "fr") {
      return French
    }

    if (locale == "cn") {
      return Mandarin
    }

    if (locale == "es") {
      return Spanish
    }

    if (locale == "pt") {
      return Portuguese
    }

    if (locale == "de") {
      return German
    }

    if (locale == "ja") {
      return Japanese
    }

    if (locale == "it") {
      return Italian
    }

    if (locale == "tr") {
      return Turkish
    }
  }

  getdateFormat(locale) {
    if (locale == "ar") {
      return 'j F'
    }

    if (locale == "en") {
      return 'M j'
    }

    if (locale == "fr") {
      return 'j M. '
    }
    
    if (locale == "cn") {
      return 'M j'
    }

    if (locale == "es") {
      return 'M j'
    }

    if (locale == "pt") {
      return 'M j'
    }

    if (locale == "de") {
      return 'M j'
    }

    if (locale == "ja") {
      return 'M j'
    }

    if (locale == "it") {
      return 'M j'
    }

    if (locale == "tr") {
      return 'M j'
    }
  }

  getdestinationHtmlTemplate(destination) {
    var div = document.createElement('div');
    div.style = "cursor: pointer;"
    div.innerHTML = `
          <div class="icon-box card flex-row align-items-center card-hover border-0 rounded-1 shadow-none py-2">
            <div class="icon-box-media bg-gray-100 text-black rounded-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin">
                <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                <circle cx="12" cy="10" r="3"></circle>
              </svg>
            </div>
            <h3 class="icon-box-title fs-sm mx-2 mb-0 fw-normal">${destination}</h3>
          </div>
          `
    return div 
  }
}
