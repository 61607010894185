import { Controller } from "@hotwired/stimulus";
import flatpickr from 'flatpickr';
import rangePlugin from "flatpickr/dist/plugins/rangePlugin"
import { AlgerianArabic }  from "flatpickr/dist/l10n/ar-dz.js"


let calendar;
// Connects to data-controller="date-picker"
export default class extends Controller {
  static targets = [
    "checkinDate",
    "checkoutDate",
    "preview",
    "reservationNights",
    "reservationSubTotal",
    "reservationTotal"
  ]

  static values = {
    price: Number,
    calendar: Array,
    inline: Boolean,
  }

  initialize() {
    
  }

  connect() {     
    let controller = this;
    let  preview = document.getElementById("preview");
    let  reservationNights = document.getElementById("reservationNights");
    let  reservationSubTotal = document.getElementById("reservationSubTotal");
    let  reservationTotal = document.getElementById("reservationTotal");

    let config = {
      "locale": AlgerianArabic,
      showMonths: 2,
      dateFormat: 'Y-m-d',
      mode: "range",
      inline: controller.inlineValue,
      minDate: "today",
      nextArrow: "<i class='fi-chevrons-right fs-7'></i>",
      prevArrow: "<i class='fi-chevrons-left fs-7'></i>",
      disable: controller.calendarValue,
      "plugins": [
        new rangePlugin({ input: this.checkoutDateTarget})
      ],  
      onValueUpdate: (selectedDates, dateStr, instance) => {
        if (controller.inlineValue === true) {
          calendar.clear();
          this.previewTarget.style.display = 'none';
          this.reservationNightsTarget.innerHTML = "";
          this.reservationSubTotalTarget.innerHTML = "";
          this.reservationTotalTarget.innerHTML = "";
          if (selectedDates === null) {
            return reservationNights.innerHTML ="0";
          }else{
            
            let time = selectedDates[1].getTime() - selectedDates[0].getTime();
            let msInDay = 1000 * 3600 * 24;
            let nights = time / msInDay;
            let subtotal = nights * parseInt(controller.priceValue)
            let total = nights * parseInt(controller.priceValue)
  
            if (nights > 0) {
              preview.style.display = 'block';
            }else{
              preview.style.display = 'none';
            }
            reservationNights.innerHTML = nights;
            reservationSubTotal.innerHTML = subtotal + '.00 DZD';
            reservationTotal.innerHTML = total + '.00 ';
          }          
        }
      },
      onClose: (selectedDates, dateStr, instance) => {
        if (controller.inlineValue === false) {
          if (selectedDates === null) {
            return controller.reservationNightsTarget.innerHTML ="0";
          }else{
            
            let time = selectedDates[1].getTime() - selectedDates[0].getTime();
            let msInDay = 1000 * 3600 * 24;
            let nights = time / msInDay;
            let subtotal = nights * parseInt(controller.priceValue)
            let total = nights * parseInt(controller.priceValue)
  
            if (nights > 0) {
              preview.style.display = 'block';
            }else{
              preview.style.display = 'none';
            }
            reservationNights.innerHTML = nights;
            reservationSubTotal.innerHTML = subtotal + '.00 DZD';
            reservationTotal.innerHTML = total + '.00 ';
          }
          
        }
      }
    }
    
    calendar = flatpickr(this.checkinDateTarget, config);
  }

  clearCalendar() {
    
    if (this.inlineValue === false) {
      calendar.clear();
      this.previewTarget.style.display = 'none';
      this.reservationNightsTarget.innerHTML = "";
      this.reservationSubTotalTarget.innerHTML = "";
      this.reservationTotalTarget.innerHTML = "";                              
    }else {
      calendar.redraw();
    }
  }
}
