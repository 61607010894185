import { Controller } from "@hotwired/stimulus"
import SmoothScroll from 'smooth-scroll';

export default class extends Controller {
    initialize() {
        this.scroll()
    }
    scroll() {
        var scroll = new SmoothScroll('[data-scroll]', {
            header: '[data-scroll-header]',
            speed: 800,
            speedAsDuration: true,
            updateURL: false,
            offset: function offset(anchor, toggle) {
                return toggle.dataset.scrollOffset || 40;
            },
        });
    }
}
