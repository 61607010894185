import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "output", 
        "removeBtn",
    ]
    static values = {
        min: Number,
        value: Number
    }
    initialize() {
        if(this.hasValueValue){
            //this.outputTarget.value = this.valueValue
            this.outputTarget.setAttribute("value", this.valueValue)
        } else {
            //this.outputTarget.value = ((this.hasMinValue) ? this.minValue : 0)
            this.outputTarget.setAttribute("value", ((this.hasMinValue) ? this.minValue : 0))
        }
        
        if(this.hasValueValue && this.hasMinValue && this.valueValue !== this.minValue){
            //this.outputTarget.value = this.valueValue
            this.removeBtnTarget.disabled = false
        } else {
            //this.outputTarget.value = ((this.hasMinValue) ? this.minValue : 0)
            this.removeBtnTarget.disabled = true
        }
    }

    add() {
        let min = ((this.hasMinValue) ? this.minValue : 0)
        //this.outputTarget.value = ++this.outputTarget.value
        this.outputTarget.setAttribute("value", ++this.outputTarget.value)
        if (this.outputTarget.value > min) {
            this.removeBtnTarget.disabled = false
        }
    }

    remove() {
        let min = ((this.hasMinValue) ? this.minValue : 0)
        //this.outputTarget.value = --this.outputTarget.value
        this.outputTarget.setAttribute("value", --this.outputTarget.value)
        if (this.outputTarget.value < min + 1) {
            this.removeBtnTarget.disabled = true
        }
    }

    editNumberGuest() {
        this.guestInputTarget.value = this.outputTarget.value
    }
}
