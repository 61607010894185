import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="messenger"
export default class extends Controller {
  static targets = [
    "messagesContainer"
  ]
  connect() {
    let controller = this
    
    let modal = controller.messagesContainerTarget.querySelector('.pre-modal')
    let modalDialog = controller.messagesContainerTarget.querySelector('.pre-modal-dialog ')
    let modalContent = controller.messagesContainerTarget.querySelector('.pre-modal-content')
    let modalBody = controller.messagesContainerTarget.querySelector('.pre-modal-body')
    
    
    controller.updateAttributes(controller, modal, modalDialog, modalContent, modalBody)

    window.addEventListener("resize", (e) => {
      controller.updateAttributes(controller, modal, modalDialog, modalContent, modalBody)
    })

  }

  updateAttributes(controller, modal, modalDialog, modalContent, modalBody, ) {
    if (window.innerWidth > 768) {
      modal.classList.remove('modal', 'position-absolute')
      modal.classList.add('h-100', 'pre-modal')
      modal.removeAttribute("id")
      modal.removeAttribute("tabindex")
      modal.removeAttribute("role")
      modal.removeAttribute("data-controller")
      modal.removeAttribute("data-modal-style-value")
      modal.removeAttribute("aria-modal")
      modal.removeAttribute("aria-hidden")
      modal.removeAttribute("style")
      
      modalDialog.classList.remove('modal-dialog', 'modal-fullscreen')
      modalDialog.classList.add('h-100', 'pre-modal-dialog')
      modalDialog.removeAttribute("role")

      modalContent.classList.remove('modal-content')
      modalContent.classList.add('h-100', 'pre-modal-content')

      modalBody.classList.remove('modal-body')
      modalBody.classList.add('h-100', 'pre-modal-body') 
    } else {
      modal.classList.add('modal', 'position-absolute')
      modal.classList.remove('h-100', 'pre-modal')
      modal.setAttribute("id", "chatMessages")
      modal.setAttribute("tabindex", "-1")
      modal.setAttribute("role", "dialog")
      modal.setAttribute("data-controller", "modal")
      modal.setAttribute("data-modal-style-value", "false")

      modalDialog.classList.add('modal-dialog', 'modal-fullscreen')
      modalDialog.classList.remove('h-100', 'pre-modal-dialog')
      modalDialog.setAttribute("role", "document")

      modalContent.classList.add('modal-content')
      modalContent.classList.remove('h-100', 'pre-modal-content')

      modalBody.classList.add('modal-body')
      modalBody.classList.remove('h-100', 'pre-modal-body')
    }
  }
}
