import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-items"
let x
export default class extends Controller {
  static targets = [
    "container",
    "left",
    "right"
  ]
  connect() {    
    x = Math.ceil(this.containerTarget.scrollWidth / Math.ceil(this.containerTarget.offsetWidth*60/100)-1)
    
    if (x == Math.ceil(this.containerTarget.scrollWidth / Math.ceil(this.containerTarget.offsetWidth*60/100)-1)) {
      this.rightTarget.hidden = true
    }
    window.addEventListener('resize', e => {
      x = Math.ceil(this.containerTarget.scrollWidth / Math.ceil(this.containerTarget.offsetWidth*60/100)-1)
      console.log(x)
    });
  }

  toLeft(e) {
    console.log(x)
    e.preventDefault();
    this.containerTarget.scrollLeft -= Math.ceil(this.containerTarget.offsetWidth*60/100);    
    x--
    if (x == 0) {
      this.leftTarget.hidden = true
    }
    if (this.rightTarget.hidden) {
      this.rightTarget.hidden = false
    } 
  }

  toRight(e) {
    console.log(x)
    e.preventDefault();
    x++
    this.containerTarget.scrollLeft += Math.ceil(this.containerTarget.offsetWidth*60/100);   
    if (this.leftTarget.hidden) {
      this.leftTarget.hidden = false
    } 
    if (x == Math.ceil(this.containerTarget.scrollWidth / Math.ceil(this.containerTarget.offsetWidth*60/100)-1)) {
      this.rightTarget.hidden = true
    }
  }

  
}
