import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="nested-form"
export default class extends Controller {
  static targets = [
    "template",
    "container",
    "order",
  ]
  connect() {
    
  }

  addItem(e) {
    e.preventDefault();
    this.containerTarget.insertAdjacentHTML(
      "beforeend", 
      this.templateTarget.innerHTML
    )
    var c = 1
    for( var target of this.orderTargets ){
      target.value = c
      c ++ 
    }
    
  }
  
  removeItem(e) {
    e.preventDefault();
    var parent = e.target.parentNode;
    parent.parentNode.removeChild(parent);
    var c = 1
    for( var target of this.orderTargets ){
      target.value = c
      c ++ 
    }
  }
  
}
