import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        document.addEventListener('turbo:load', () => {
            var preloader = document.querySelector('.page-loading');
            if (preloader !== null) {
                preloader.classList.remove('active');
                setTimeout(function () {
                    preloader.remove();
                }, 2000);
            }
        })
    }
}