import { Controller } from "@hotwired/stimulus"
import lightGallery from 'lightgallery';

// Plugins
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import lgFullscreen from 'lightgallery/plugins/fullscreen'
import lgVideo from 'lightgallery/plugins/video'
import lgAutoplay from 'lightgallery/plugins/autoplay'

// Connects to data-controller="light-gallery"
export default class extends Controller {
  initialize() {
    var gallery = this.element;
    var thumbnails = gallery.dataset.thumbnails ? true : false;
    lightGallery(gallery, {
        plugins: [lgZoom, lgThumbnail, lgFullscreen, lgVideo, lgAutoplay ],
        selector: '.gallery-item',
        speed: 500,
        thumbnail: thumbnails,
        download: false,
        autoplay: true,
        slideShowAutoplay: true,
        slideShowInterval: 3000 
    });
  }

}
