import { Controller } from "@hotwired/stimulus"
import L from 'leaflet'
// Connects to data-controller="leaflet-map"
var map;
var myIcon = L.icon({
  iconUrl: '/assets/map/marker-icon-a09d7735a89827629b09f5dca1376aae251a53b4b3bf8d58065725bd26006de2.png',
  //iconSize: [80],
  iconSize: [50, 50],
  iconAnchor: [22, 94],
  popupAnchor: [-3, -76],
});

export default class extends Controller {
  static targets = ["map"]
  static values = {
    message: String,
  }

  connect() {
    this.initMap()
  }

  initMap() {
    let latlng = L.latLng(this.data.get("latitude"),this.data.get("longitude"));
    map = L.map(this.mapTarget, {
      center: [this.data.get("latitude"), this.data.get("longitude")],
      zoom: 13,
    });
    // https://api.maptiler.com/maps/pastel/{z}/{x}/{y}.png?key=daX45qZm7YQWvcaMga6c
    //
    //https://api.maptiler.com/maps/openstreetmap/{z}/{x}/{y}.jpg?key=daX45qZm7YQWvcaMga6c
    L.tileLayer('https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=daX45qZm7YQWvcaMga6c', {
        maxZoom: 20,
        attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
    }).addTo(map);

    var marker = L.marker( latlng ,{
      icon: myIcon
    }).addTo(map);

    marker.bindPopup("<div class='text-center p-3'>"+ this.data.get("message") +"</div>",  {
      closeButton: false
    });
    
    marker.on('click', this.onMarkerClick);
    
  }

  onMarkerClick(e) {
    map.setView(e.latlng, 19);
    map.setView(e.latlng);
  }
}
