import { Controller } from "@hotwired/stimulus"
let markers = []
// Connects to data-controller="place-picker"
export default class extends Controller {
  static targets = [
    "country", 
    "state", 
    "city"
  ]
  static values = {
    url: String,
    selected: String,
    cityurl: String,
    selectedstate: String
  }

  initialize() {    
    this.stateTarget.disabled = false
    this.cityTarget.disabled = true
    if (this.selectValue().length > 0) {      
      this.fetch()
    }
  }

  selectValue() {
    return this.countryTarget.selectedOptions[0].value
  }

  fetch() {
    if (this.selectValue().length > 0) {      
      this.stateTarget.disabled = false
    }else {
      this.cityTarget.disabled = true
      this.stateTarget.disabled = true
    }
    // fetch(`${this.urlValue}?${this.params()}`)
    while (this.cityTarget.options.length > 0) {                
      this.cityTarget.remove(0);
    } 
    fetch(`${this.urlValue}?${this.params()}`, {
      headers: {
        'Accept': "text/vnd.turbo-stream.html",
        'Accept-Language': '*'
      }
    }).then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

  params() {
    let params = new URLSearchParams()
    params.append('id', this.selectValue())
    params.append('select', this.stateTarget.id)
    params.append('selectcity', this.cityTarget.id)
    return params
  }

  selectStateValue() {
    return this.stateTarget.selectedOptions[0].value
  }

  fetchCities() {
    if (this.selectStateValue().length > 0) {      
      this.cityTarget.disabled = false
    }else {
      this.cityTarget.disabled = true
    }
    // fetch(`${this.urlValue}?${this.params()}`)
    fetch(`${this.cityurlValue}?${this.stateParams()}`, {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

  stateParams() {
    let params = new URLSearchParams()
    params.append('id', this.selectStateValue())
    params.append('select', this.cityTarget.id)
    params.append('selected', 0)
    return params
  }
}
