import { Controller } from "@hotwired/stimulus"
import debounce from "debounce";

// Connects to data-controller="sacoddo-search"
export default class extends Controller {
  static targets = [
    "searchForm",
    "searchFormS",
    "searchInput",
    "searchFormBtn"
  ]
  initialize() {
    let controller = this    
    controller.submit = debounce(controller.submit.bind(controller), 300);
    controller.searchInputTarget.addEventListener("keypress", function(event) {
      // If the user presses the "Enter" key on the keyboard
      if (event.key === "Enter") {
        // Cancel the default action, if needed
        event.preventDefault();
        if (controller.searchInputTarget.value.trim().length !== 0) {
          controller.forceSubmitS()
        }
      }
    });
  }

  connect(){
    let controller = this
    const searchContainer = document.querySelector(".offcanvas-sacoddo-search")

    controller.classObserver(searchContainer, (node) => {
      if (node.classList.contains('show')) {
        setTimeout(() => {
          controller.searchInputTarget.focus()
          
          let val = controller.searchInputTarget.value
          controller.searchInputTarget.value = ""
          controller.searchInputTarget.value = val
        }, 500)
      }
    })
  }

  submit(_event) {
    const toggler = document.querySelector(".search-toggler")
    var placeHolder = toggler.dataset.default

    if (this.searchInputTarget.value == "") {
      toggler.placeholder = placeHolder
    } else {
      toggler.placeholder = this.searchInputTarget.value
    }
    this.searchFormTarget.requestSubmit();
  }

  forceSubmit(_event) {
    this.searchFormTarget.removeAttribute("data-turbo-frame")
    this.searchFormTarget.removeAttribute("data-action")
    this.searchFormTarget.requestSubmit();
  }

  forceSubmitS(_event) {
    if (this.hasSearchFormSTarget) {      
      this.searchFormSTarget.closest("form").submit();
    }
  }

  classObserver(el, callback) {
    let observer = new MutationObserver((mutations) => {
      mutations.forEach( mutation => {
        if (mutation.type === "attributes" && mutation.attributeName === "class") {
          callback(mutation.target)
        }
      })
    })
    observer.observe(el, {attributes: true})
    return observer.disconnect
  }
}
