import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-gender"
export default class extends Controller {
  static targets = [
    "container",
    "companyTemplate"
  ]

  static values = {
    name: String,
  }

  connect() {
    let controller = this
    this.element.getElementsByClassName('form-check-input');
    console.log()

    this.element.querySelectorAll('input[type=radio][name="user[gender]"]').forEach((input) => {
      input.addEventListener("change", function() {
        // Get the selected radio button
        var selectedRadioButton = controller.element.querySelector('input[name="user[gender]"]:checked');
        if (selectedRadioButton.value === "company") {
          console.log("changed")
          controller.containerTarget.innerHTML = controller.companyTemplateTarget.innerHTML
        }else{
          controller.containerTarget.innerHTML = ""
        }
      });
    });
  }
}
