import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="weekly-planner"
export default class extends Controller {
  static targets = [
    "daycheckbox",
    "timecontainer"
  ]
  initialize() {
    this.daycheckboxTarget.checked = false
    let inputElements = this.timecontainerTarget.querySelectorAll('input');
    inputElements.forEach(function(element) {
      element.value = null
      element.disabled = true;
    });
  }
  connect() {
    let controller = this
    let inputElements = controller.timecontainerTarget.querySelectorAll('input');
    controller.daycheckboxTarget.addEventListener('change', function() {
      if (controller.daycheckboxTarget.checked) {
        inputElements.forEach(function(element) {
          element.disabled = false;
        });
      } else {
        inputElements.forEach(function(element) {
          element.value = null
          element.disabled = true;
        });
      }
    });
  }
}
