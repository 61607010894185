import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="modal"
export default class extends Controller {

  static values = {
    style: Boolean,
  }

  initialize() {    
    let controller = this
    let modalDialog = this.element.querySelector('.modal-dialog')
    
    if (!controller.hasStyleValue) {
      controller.appendStyle(modalDialog)
      window.addEventListener("resize", ()=>{
        controller.appendStyle(modalDialog)
      });    
    }

    controller.element.addEventListener('shown.bs.modal', () => {
      controller.element.setAttribute('aria-hidden', false);
      window.history.pushState(controller.element.id, "", null)
      if (history.state && history.state === `${controller.element.id}`) {
        window.addEventListener('popstate', (event) => {
          bootstrap.Modal.getInstance(controller.element).hide()
        }, {once: true})        
      }
      
      setTimeout(function() {
        controller.element.focus();
      }, 1);
    });

    controller.element.addEventListener('hidden.bs.modal', () => {
      //controller.element.querySelector('.modal-content').remove()
      if (history.state && history.state === `${controller.element.id}`) {
        window.history.replaceState(controller.element.id, "", null)
        console.log(history.state)
        controller.element.setAttribute('aria-hidden', true);        
      }
    });

    
  } 
  
  connect() {
    //console.log(`is connected to modal`)
  }

  disconnect() {
    //console.log(`is disconnected from modal `)
  }

  appendStyle(modalDialog) {
    if (window.innerWidth > 500) {
      modalDialog.classList.remove("modal-fullscreen")
      modalDialog.classList.add("modal-lg", "modal-dialog-scrollable")
    } else {
      modalDialog.classList.remove("modal-lg", "modal-dialog-scrollable")
      modalDialog.classList.add("modal-fullscreen")
    }
  }
}

class PopupManager {
  constructor(popupElement) {
    this.popupElement = document.getElementById(popupElement);
    this.isPopupOpen = false;

    // Bind the event listener to the popstate event
    window.addEventListener('popstate', this.handlePopState.bind(this));

    // Initial state handling
    if (history.state && history.state.popupOpen) {
        this.isPopupOpen = true;
    } else {
        history.replaceState({ popupOpen: false }, null, null);
    }
  }

  openPopup() {
    if (!this.isPopupOpen) {
        this.isPopupOpen = true;
        history.pushState({ popupOpen: true }, null, null);
    }
  }

  closePopup() {
    if (this.isPopupOpen) {
        this.isPopupOpen = false;
        history.back();  // This will trigger the popstate event
    }
  }

  handlePopState(event) {
    const currentState = history.state;
    if (currentState && currentState.popupOpen) {
        this.isPopupOpen = true;
    } else {
        this.isPopupOpen = false;
    }
  }
}
