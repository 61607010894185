import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="auto-play"
export default class extends Controller {
  static values = {
    controls: Boolean,
    muted: Boolean,
  }
  initialize() {
    let controller = this
    let current_time = 0
    this.element.addEventListener("mouseover", function() {
      this.pause();
      this.muted = controller.mutedValue;
      this.currentTime = current_time;
      this.controls = controller.controlsValue; 
      this.setAttribute("controlsList", "nodownload")
      this.play();
    });
    
    this.element.addEventListener("mouseleave", function() {
      this.pause();
      current_time = this.currentTime
      this.removeAttribute("controls") 
    });
  }
}
