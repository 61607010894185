import { Controller } from "@hotwired/stimulus"
import debounce from "debounce";

// Connects to data-controller="currency-search"
export default class extends Controller {
  initialize() {
    let controller = this
    
    controller.submit = debounce(controller.submit.bind(controller), 100);
  }
  
  connect() {
  }

  submit(_event) {
    this.element.requestSubmit();
  }
}
