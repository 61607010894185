import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="post"
export default class extends Controller {
  static targets = [
    "uploadsContainer",
    "contentContainer",
    "btnToContent",
    "btnToUploads"
  ]

  connect() {
    let controller = this
  }

  addPhotos(e) {
    e.preventDefault();
    this.uploadsContainerTarget.insertAdjacentHTML(
      "beforeend", 
      this.photoTemplateTarget.innerHTML
    )
    this.photoBtnTarget.disabled = true
  }

  removePhotos(e) {
    e.preventDefault();
    var parent = e.target.parentNode;
    parent.parentNode.removeChild(parent);
    this.photoBtnTarget.disabled = false
  }

  addCategory(e) {
    e.preventDefault();
    this.uploadsContainerTarget.insertAdjacentHTML(
      "beforeend", 
      this.categoryTemplateTarget.innerHTML
    )
    this.categoryBtnTarget.disabled = true
  }

  removeCategory(e) {
    e.preventDefault();
    var parent = e.target.parentNode;
    parent.parentNode.removeChild(parent);
    this.categoryBtnTarget.disabled = false
  }
}
