import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="mobile-gallery"
export default class extends Controller {
  static targets = [
    "spinner"
  ]

  initialize() {
    this.slide = this.element
    this.slidePlayState = "running"
    this.slideMutedState = true
    this.slideItemStartTime = Date.now();
    this.slideItemPlayedTime = 0;
    this.slidePausedTime = Date.now();
    this.active = 0
    this.init()
  }

  disconnect() {
    clearTimeout(this.timeout)
  }

  init() {
    let controller = this
    this.next = this.next.bind(this)
    this.prev = this.prev.bind(this)
    
    //create slide elements
    this.itemsToCreate = this.slide.querySelectorAll('.slide-items-create > *')
    this.createElements()

    //start sliding
    controller.items = controller.slide.querySelectorAll('.slide-items > *')
    controller.thumb = controller.slide.querySelector('.slide-thumbs')
    controller.addThumbItems()
    controller.activeSlide(0)
    controller.addNavigation()

  }

  createElements() {
    const container = this.slide.querySelector('.slide-items');
    this.itemsToCreate.forEach((item, index) => {
      if (item.classList.contains("gallery-video")) {
        container.appendChild(this.createVideoElement(item, index));
      }
      if (item.classList.contains("gallery-image")) {
        container.appendChild(this.createImageElement(item, index));
      }
    })
  }

  createVideoElement(item, index) {
    let controller = this
    const videoMetaData = JSON.parse(item.getAttribute("data-video-metadata"))

    const galleryItem = document.createElement('div');
    galleryItem.classList.add('bg-black')
    galleryItem.classList.add('d-flex', 'justify-content-center', 'align-items-center')
    galleryItem.classList.add('gallery-video')
    galleryItem.style = "max-height: 30rem; height: 100%;"

    const video = document.createElement('video');
    video.src = item.getAttribute("data-video-src");
    video.setAttribute("data-video-metadata", item.getAttribute("data-video-metadata"));
    video.controls = false;
    video.muted = this.slideMutedState;
    video.autoplay = false;
    video.loop = false;
    video.preload = 'none'
    video.classList.add('video')
    video.classList.add('h-100', 'w-100', 'object-fit-cover')
    
    let downEvents = ['mousedown', 'touchstart']
    let upEvents = ['mouseup', 'touchend']

    video.addEventListener('contextmenu', (e) => {
      e.preventDefault()
    });

    downEvents.forEach(eventName => {
      video.addEventListener(eventName, (e) => {
        //e.preventDefault()
      });
    });

    upEvents.forEach(eventName => {
      video.addEventListener(eventName, (e) => {
        e.preventDefault()
      });
    });

    // video.addEventListener('play', (event) => {
    //   console.log("playing")
    // });
    // video.addEventListener('pause', (event) => {
    //   console.log("pausing")
    // });

    video.addEventListener("playing", function (e) {
      controller.autoSlide(e.target.duration - e.target.currentTime)
      controller.thumbItems[index].classList.remove('paused')
      controller.thumbItems[index].classList.add('running')
    });
    
    video.addEventListener('waiting', (e) => {
      controller.pauseAutoSlide()
      controller.thumbItems[index].classList.add('paused')
      controller.thumbItems[index].classList.remove('running')
    });

    const pauseBtn = document.createElement('i');    
    pauseBtn.classList.add('video-control-pause')
    pauseBtn.classList.add('border-0', 'btn', 'btn-xs', 'btn-icon', 'bi', 'bi-pause-fill', 'bg-blur', 'text-light', 'rounded-3', 'slide-btn-icon')
    pauseBtn.addEventListener('click', (e) => {
      let video = e.target.parentNode.parentNode.querySelector('.video')
      if (video.paused) {
        this.autoSlide(video.duration - video.currentTime)
        video.play()
        this.thumbItems[index].classList.remove('paused')
        this.thumbItems[index].classList.add('running')
        e.target.classList.remove('bi-play-fill')
        e.target.classList.add('bi-pause-fill')

      } else {
        this.pauseAutoSlide()
        video.pause()
        this.thumbItems[index].classList.remove('running')
        this.thumbItems[index].classList.add('paused')
        e.target.classList.remove('bi-pause-fill')
        e.target.classList.add('bi-play-fill')
        
      }
    })

    const muteBtn = document.createElement('i');    
    muteBtn.classList.add('video-control-mute')    
    muteBtn.classList.add('border-0', 'btn', 'btn-xs', 'btn-icon', 'bi', 'bi-volume-up-fill', 'bg-blur', 'text-light', 'rounded-3', 'slide-btn-icon')
    muteBtn.addEventListener('click', (e) => {
      let video = e.target.parentNode.parentNode.querySelector('.video')
      // console.log()
      if (video.muted) {
        video.volume = 1
        controller.slideMutedState = false
        video.muted = controller.slideMutedState
        e.target.classList.remove('bi-volume-mute-fill')
        e.target.classList.add('bi-volume-up-fill')
      } else {
        video.volume = 0
        controller.slideMutedState = true
        video.muted = controller.slideMutedState
        e.target.classList.add('bi-volume-mute-fill')
        e.target.classList.remove('bi-volume-up-fill')
      }      
    })
    
    const controls = document.createElement('div');
    controls.classList.add('p-2', 'position-absolute', 'bottom-0', 'start-0', 'zindex-1', 'd-flex', 'gap-2')

    controls.appendChild(pauseBtn);
    controls.appendChild(muteBtn);

    galleryItem.appendChild(video);
    galleryItem.appendChild(controls);

    return galleryItem
  }
  
  createImageElement(item, index) {
    let controller = this
    const imgMetaData = JSON.parse(item.getAttribute("data-img-metadata"))

    const galleryItem = document.createElement('div');
    galleryItem.classList.add('bg-black')
    galleryItem.classList.add('d-flex', 'justify-content-center', 'align-items-center')
    galleryItem.classList.add('w-100', 'rounded-0')
    galleryItem.style = "max-height: 30rem; height: 100%;"

    const image = document.createElement('img');
    image.src = item.getAttribute("data-img-src");
    image.classList.add('h-100', 'w-100', 'object-fit-cover')
    image.classList.add('rounded-0', 'image')

    let downEvents = ['mousedown', 'touchstart']
    let upEvents = ['mouseup', 'touchend']

    image.addEventListener('contextmenu', (e) => {
      e.preventDefault()
    });
    downEvents.forEach(eventName => {
      image.addEventListener(eventName, (e) => {
        //e.preventDefault()
        this.slidePausedTime = Date.now();
        this.pauseAutoSlide()
        this.slidePlayState = "paused"
        this.thumbItems[index].classList.remove('running')
        this.thumbItems[index].classList.add('paused')

        this.slide.querySelector('.slide-nav-btns').classList.add('slide-hold')
        this.slide.querySelector('.slide-nav').classList.add('slide-hold')
      });
    });

    upEvents.forEach(eventName => {
      image.addEventListener(eventName, (e) => {
        e.preventDefault()
        let t = this.slidePausedTime - this.slideItemStartTime
        this.slideItemPlayedTime += t
        console.log(this.slideItemPlayedTime)
        this.autoSlide(5 - this.slideItemPlayedTime/1000)
        this.slideItemStartTime = Date.now();
        this.slidePlayState = "running"
        this.thumbItems[index].classList.remove('paused')
        this.thumbItems[index].classList.add('running')
        this.slide.querySelector('.slide-nav-btns').classList.remove('slide-hold')
        this.slide.querySelector('.slide-nav').classList.remove('slide-hold')
      });
    });
    
    // const pauseBtn = document.createElement('i');    
    // pauseBtn.classList.add('image-control-pause')
    // pauseBtn.classList.add('border', 'btn', 'btn-sm', 'btn-icon', 'bi', 'bi-pause-fill', 'bg-light', 'text-dark', 'rounded-circle', 'slide-btn-icon')
    // pauseBtn.addEventListener('click', (e) => {
    //   let image = e.target.parentNode.parentNode.querySelector('.image')
    //   //this.slideCurrentTime = Date.now();
    //   if (this.slidePlayState === "paused") {
    //     let t = this.slidePausedTime - this.slideItemStartTime
    //     this.slideItemPlayedTime += t
    //     console.log(this.slideItemPlayedTime)
    //     this.autoSlide(5 - this.slideItemPlayedTime/1000)
    //     this.slideItemStartTime = Date.now();
    //     this.slidePlayState = "running"
    //     this.thumbItems[index].classList.remove('paused')
    //     this.thumbItems[index].classList.add('running')
    //     e.target.classList.remove('bi-play-fill')
    //     e.target.classList.add('bi-pause-fill')
    //   } else {
    //     this.slidePausedTime = Date.now();
    //     this.pauseAutoSlide()
    //     //console.log(Date.now() - this.slideCurrentTime)
    //     this.slidePlayState = "paused"
    //     this.thumbItems[index].classList.remove('running')
    //     this.thumbItems[index].classList.add('paused')
    //     e.target.classList.remove('bi-pause-fill')
    //     e.target.classList.add('bi-play-fill')        
    //   }
    // })

    // const controls = document.createElement('div');
    // controls.classList.add('p-2', 'position-absolute', 'bottom-0', 'start-0', 'zindex-1', 'd-flex', 'gap-2')

    // controls.appendChild(pauseBtn);

    galleryItem.appendChild(image);
    //galleryItem.appendChild(controls);

    return galleryItem
  }

  createIcon(classes){
    const icon = document.createElement('i');
    classes.forEach((item, index) => {
      icon.classList.add(item)
    })
    return icon
  }

  addThumbItems() {
    this.items.forEach((item, index) => {
      this.thumb.innerHTML += `<span class="slide-thumb-item"></span>`
    })
    this.thumbItems = Array.from(this.thumb.children)
  }

  addNavigation() {
    const nextBtn = this.slide.querySelector('.slide-next')
    const prevBtn = this.slide.querySelector('.slide-prev')
    nextBtn.addEventListener('click', this.next)
    prevBtn.addEventListener('click', this.prev)
  }

  /** @param {Number} index */
  activeSlide(index) {
    this.active = index
    this.items.forEach((item) => item.classList.remove('active'))
    this.items[index].classList.add('active')
    this.thumbItems.forEach((item) => item.classList.remove('active'))
    this.thumbItems.forEach((item) => item.classList.remove('test'))
    this.thumbItems.forEach((item) => item.classList.remove('paused'))
    this.thumbItems.forEach((item) => item.classList.remove('running'))

    let duration = this.getAnimationDuration(this.items[index], index)

    this.thumbItems[index].classList.add('active')
    this.thumbItems[index].classList.add('progressBar')
    this.thumbItems[index].classList.add('test')
    this.thumbItems[index].classList.add('running')

    this.thumbItems[index].style.animationDuration = `${duration}s`;
    this.slideItemStartTime = Date.now();
    this.slideItemPlayedTime = 0

    if (this.items[index].classList.contains("gallery-video")) {
      let video = this.items[index].querySelector(".video")
      let pauseBtn = this.items[index].querySelector(".video-control-pause")
      let muteBtn = this.items[index].querySelector(".video-control-mute")

      video.muted = this.slideMutedState
      if (this.slideMutedState) {
        muteBtn.classList.remove('bi-volume-up-fill')
        muteBtn.classList.add('bi-volume-mute-fill')        
      } else {
        muteBtn.classList.remove('bi-volume-mute-fill')
        muteBtn.classList.add('bi-volume-up-fill')
      }
      
    } 

    this.autoSlide(duration)
  }

  getAnimationDuration(item, index) {
    if (item.classList.contains("gallery-video")) {
        let video = item.querySelector(".video")
        let videoMetaData = JSON.parse(video.getAttribute("data-video-metadata"))
        let duration = videoMetaData.duration + 0.2;
        video.currentTime = 0
        video.muted = true
        video.play();
        return `${duration}`;
    } else {
        return "5"
    }
  }

  next() {    
    if (this.items[this.active].classList.contains("gallery-video")) {
        let video = this.items[this.active].querySelector(".video")
        let pauseBtn = this.items[this.active].querySelector(".video-control-pause")
        video.muted = true
        video.pause()
        pauseBtn.classList.add('bi-pause-fill')
        pauseBtn.classList.remove('bi-play-fill')
    }    
    if (this.active < this.items.length - 1) {
      this.activeSlide(this.active + 1)
    } else {
      this.activeSlide(0)
    }
  }

  prev() {
    if (this.items[this.active].classList.contains("gallery-video")) {
      let video = this.items[this.active].querySelector(".video")
      let pauseBtn = this.items[this.active].querySelector(".video-control-pause")

      video.muted = true
      video.pause()
      pauseBtn.classList.add('bi-pause-fill')
      pauseBtn.classList.remove('bi-play-fill')
    } 
    if (this.active > 0) {
      this.activeSlide(this.active - 1)
    } else {
      this.activeSlide(this.items.length - 1)
    }
  }

  autoSlide(duration) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(this.next, duration*1000)
  }

  pauseAutoSlide() {
    clearTimeout(this.timeout);
  }
}