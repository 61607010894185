import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="text-editor"
export default class extends Controller {
  connect() {
    console.log('text editor connected')
    let controller = this

    document.addEventListener('trix-change', function (event) {
      const editor = event.target;
      const currentLength = editor.innerText.length;

      if (currentLength > 0 ) {
        controller.PostBtnState(false);
      } else {
        if (controller.dropZone.files.length < 1) {
          controller.PostBtnState(true);
        }
      }
    });
  }
}
