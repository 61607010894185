import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter"
export default class extends Controller {
  
  connect() {
    //console.log(this.element)
    let controller = this
  }

  clearFilters() {
    this.element.reset()
  }
}
