import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-map"
export default class extends Controller {
  static targets = ["map", "latitude", "longitude"]
  connect() {
    if (typeof (google) != "undefined") {
      this.initMap()
    }
  }

  initMap() {
    const sacodooListingMap = { lat: 36.734894269667954, lng: 3.0871133873491323 };
    var position = { lat: this.data.get("latitude"), lng: this.data.get("longitude") };
    // The map, centered at Uluru
    this.map = new google.maps.Map(this.mapTarget, {
      zoom: 18,
      center: new google.maps.LatLng(this.data.get("latitude"), this.data.get("longitude")),
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      fullscreenControl: false
    });
    new google.maps.Marker({
      position: new google.maps.LatLng(this.data.get("latitude"), this.data.get("longitude")),
      map: this.map,
    });
  }
}
