import { Controller } from "@hotwired/stimulus"
import flatpickr from 'flatpickr';
// Connects to data-controller="duration-picker"
import { AlgerianArabic }  from "flatpickr/dist/l10n/ar-dz.js"
import { French }  from "flatpickr/dist/l10n/fr.js"
import { english }  from "flatpickr/dist/l10n/default.js"
let calendar;
export default class extends Controller {
  static targets = [
    "reccuring",
    "startDate",
    "endDate",
    "hourInput",
    "container",
    "onceTemplate",
    "dailyTemplate",
    "weeklyTemplate"
  ]
  static values = {
    locale: String,
    inline: Boolean,
    mode: String,
    showmonths: Number
  }

  initialize() {
    let controller = this
    if (this.selectValue(this.reccuringTarget).length > 0) {
      if (this.reccuringTarget.selectedOptions[0].value == "once") {
        this.containerTarget.innerHTML = this.onceTemplateTarget.innerHTML
        this.endDateTarget.disabled = false
        this.startDateTarget.required = true
        this.initDatePicker(this.startDateTarget)
        this.initDatePicker(this.endDateTarget)
      }
      if (this.reccuringTarget.selectedOptions[0].value == "daily") {
        this.containerTarget.innerHTML = this.dailyTemplateTarget.innerHTML
        this.endDateTarget.disabled = true
        this.endDateTarget.classList.add("bg-gray-200");
        this.initDatePicker(this.startDateTarget)
        this.initDatePicker(this.endDateTarget)
      }
      if (this.reccuringTarget.selectedOptions[0].value == "weekly") {
        this.containerTarget.innerHTML = this.weeklyTemplateTarget.innerHTML
        this.endDateTarget.disabled = true
        this.endDateTarget.classList.add("bg-gray-200");
        this.initDatePicker(this.startDateTarget)
        this.initDatePicker(this.endDateTarget)
      }
    }
  }

  connect (){
    //console.log('connecteddddd')
  }
  loadReccuring(e) {
    e.preventDefault();
    if (this.selectValue(this.reccuringTarget).length > 0) {
      if (this.reccuringTarget.selectedOptions[0].value == "once") {
        this.containerTarget.innerHTML = this.onceTemplateTarget.innerHTML
        this.endDateTarget.disabled = false
        this.startDateTarget.required = true
        this.initDatePicker(this.startDateTarget)
        this.initDatePicker(this.endDateTarget)
      }
      if (this.reccuringTarget.selectedOptions[0].value == "daily") {
        this.containerTarget.innerHTML = this.dailyTemplateTarget.innerHTML
        this.endDateTarget.disabled = true
        this.endDateTarget.classList.add("bg-gray-200");
        this.initDatePicker(this.startDateTarget)
        this.initDatePicker(this.endDateTarget)
      }
      if (this.reccuringTarget.selectedOptions[0].value == "weekly") {
        this.containerTarget.innerHTML = this.weeklyTemplateTarget.innerHTML
        this.endDateTarget.disabled = true
        this.endDateTarget.classList.add("bg-gray-200");
        this.initDatePicker(this.startDateTarget)
        this.initDatePicker(this.endDateTarget)
      }
    }
  }

  loadEndDate(e) {
    e.preventDefault();
    if (e.target.checked) {
      this.endDateTarget.disabled = false
      this.endDateTarget.classList.remove("bg-gray-200");
    } else {
      this.endDateTarget.disabled = true
      this.endDateTarget.classList.add("bg-gray-200");
      this.endDateTarget.value = ""
    }
  }

  selectValue(el) {
    return el.selectedOptions[0].value
  }

  initDatePicker(input){
    let controller = this
    let config = {
      "locale": controller.getLocale(this.localeValue),
      showMonths: controller.showmonthsValue, 
      dateFormat: controller.getdateFormat(this.localeValue),
      mode: controller.modeValue,
      allowInput: true,
      inline: controller.inlineValue,
      minDate: "today",
      nextArrow: "<i class='fi-chevrons-right fs-7'></i>",
      prevArrow: "<i class='fi-chevrons-left fs-7'></i>",      
      onDayCreate: function(dObj, dStr, fp, dayElem){
        // if (controller.hasEnabledValue) {
          
        //   //console.log(controller.enabledDates())
        //   if(controller.enabledDates().includes(`${dayElem.dateObj.getFullYear()}-${dayElem.dateObj.getMonth()+1}-${dayElem.dateObj.getDate()}`)){
        //     dayElem.className += " flatpickr-disabled flatpickr-disabled"
        //   }
        // }
      }
    }

    calendar = flatpickr(input, config)

  }
  getLocale(locale) {
    if (locale == "ar") {
      return AlgerianArabic
    }

    if (locale == "fr") {
      return French
    }

    if (locale == "en") {
      return english
    }
  }

  getdateFormat(locale) {
    if (locale == "ar") {
      return 'Y-m-d'
    }

    if (locale == "fr") {
      return 'Y-m-d '
    }

    if (locale == "en") {
      return 'Y-m-d'
    }
  }
}
