import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	connect() {
		document.addEventListener('turbo:load', () => {
			var element = document.querySelector('.btn-scroll-top'),
					scrollOffset = 600;
			if (element == null) return;
			var offsetFromTop = parseInt(scrollOffset, 10);
			window.addEventListener('scroll', function (e) {
					if (e.currentTarget.pageYOffset > offsetFromTop) {
							element.classList.add('show');
					} else {
							element.classList.remove('show');
					}
			});
		})
	}
}