import { Controller } from "@hotwired/stimulus"
let markers = []
// Connects to data-controller="google-maps"
export default class extends Controller {
  static targets = ["map", "latitude", "longitude"]

  initialize() {
    console.log(this.mapTarget.id)
    if (typeof (google) != "undefined") {
      this.initMap()
    }
  }

  initMap() { 
    //, 
    //const sacodooListingMap = { lat: 24.69863357598507, lng: 46.68651594789723 };
    const sacodooListingMap = { lat: 36.7376501343922, lng: 3.087362396355313 };
    // The map, centered at Uluru
    this.map = new google.maps.Map(this.mapTarget, {
      zoom: 9, //zoom: 15,
      center: new google.maps.LatLng(36.7376501343922, 3.087362396355313),
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      fullscreenControl: false
    });
    this.map.addListener("click", (event) => {
      this.addMarker(event.latLng);
    })
  }

  // Adds a marker to the map and push to the array.
  addMarker(position) {
    this.setMapOnAll(null);
    markers = [];
    const marker = new google.maps.Marker({
      position,
      map: this.map,
    });
    markers.push(marker);
    this.latitudeTarget.value = position.lat()
    this.longitudeTarget.value = position.lng()
  }

  // Sets the map on all markers in the array.
  setMapOnAll(map) {
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(map);
    }
  }
}
