import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="trix-anchor"
export default class extends Controller {
  connect() {
    const anchorEls = this.element.querySelectorAll("a")
    if (anchorEls.length === 0) return;

    anchorEls.forEach(el => {
      el.setAttribute("target", '_blank');
    });
  }
}
