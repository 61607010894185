import { Controller } from "@hotwired/stimulus"
import Dropzone from "dropzone"
import { DirectUpload } from "@rails/activestorage"
import Cropper from 'cropperjs';
import {
  getMetaValue,
  toArray,
  findElement,
  removeElement,
  insertAfter
} from "../helpers";

// Connects to data-controller="cropper"
export default class extends Controller {  
  static targets = ["input"];

  connect() {    
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  bindEvents() {
    this.dropZone.on("addedfile", file => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      }, 500);
    });

    this.dropZone.on("removedfile", file => {
      file.controller && removeElement(file.controller.hiddenInput);
    });

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort();
    });
  }

  

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }

  transformFile(file) {

    var myDropZone = this.source.dropZone;
    // Create the image editor overlay
    var editor = document.createElement('div');
    editor.style.position = 'fixed';
    editor.style.left = 0;
    editor.style.right = 0;
    editor.style.top = 0;
    editor.style.bottom = 0;
    editor.style.zIndex = 9999;
    editor.style.backgroundColor = '#000';
    document.body.appendChild(editor);
    // Create confirm button at the top left of the viewport
    var buttonConfirm = document.createElement('button');
    buttonConfirm.style.position = 'absolute';
    buttonConfirm.style.left = '10px';
    buttonConfirm.style.top = '10px';
    buttonConfirm.style.zIndex = 9999;
    buttonConfirm.textContent = 'Confirm';
    editor.appendChild(buttonConfirm);
    buttonConfirm.addEventListener('click', function() {
       // Get the canvas with image data from Cropper.js
       var canvas = cropper.getCroppedCanvas({
        width: 256,
        height: 256
      });
      // Turn the canvas into a Blob (file object without a name)
      canvas.toBlob(function(blob) {
        // Create a new Dropzone file thumbnail
        myDropZone.createThumbnail(
          blob,
          myDropZone.options.thumbnailWidth,
          myDropZone.options.thumbnailHeight,
          myDropZone.options.thumbnailMethod,
          false, 
          function(dataURL) {
            
            // Update the Dropzone file thumbnail
            myDropZone.emit('thumbnail', file, dataURL);
        });
      });
      // Remove the editor from the view
      document.body.removeChild(editor);
      
    });
    // Create an image node for Cropper.js
    var image = new Image();
    image.src = URL.createObjectURL(file);
    editor.appendChild(image);
    
    // Create Cropper.js
    var cropper = new Cropper(image, { aspectRatio: 1 });
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false,
  });
}