import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="offcanvas"
export default class extends Controller {
  initialize() {    
    let controller = this
    
    controller.element.addEventListener('shown.bs.offcanvas', () => {
      controller.element.setAttribute('aria-hidden', false);
      //window.history.pushState(controller.element.id, `${controller.element.id}`, document.location + `#${controller.element.id}`);
      window.history.pushState(controller.element.id, document.location);
      window.addEventListener('popstate', controller.historyListener.bind(this), false);
      controller.element.focus();
      
    });

    controller.element.addEventListener('hidden.bs.offcanvas', () => {
      console.log(window.history)
      controller.element.setAttribute('aria-hidden', true);
      if ( window.history.state === `${controller.element.id}` ) {
        window.history.go(-1);
      }
      window.removeEventListener('popstate', controller.historyListener.bind(this));
    });
  }

  historyListener(event) {   
    bootstrap.Offcanvas.getOrCreateInstance(this.element).hide()
  }
  connect() {
  }
}
