import { Controller } from "@hotwired/stimulus"
import lightGallery from 'lightgallery';
// Plugins
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import lgFullscreen from 'lightgallery/plugins/fullscreen'
import lgVideo from 'lightgallery/plugins/video'
import lgAutoplay from 'lightgallery/plugins/autoplay'

// Connects to data-controller="inline-light-gallery"
export default class extends Controller {

  static values = {
    element: Array
  }
  initialize() {
    console.log( this.elementValue )
    var gallery = this.element;
    const inlineGallery = lightGallery(gallery, {
        plugins: [lgVideo, lgAutoplay ],
        container: gallery,
        dynamic: true,
        hash: false,
        closable: false,
        slideDelay: 200,
        download: false,
        videojs: true,
        dynamicEl: this.elementValue,
    });
    
    inlineGallery.openGallery();
  }
}
