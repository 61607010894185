import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="template"
export default class extends Controller {
  static targets = [
    "fromTemplate",
    "fromContainer"
  ]
  connect() {
    //console.log("conectedss")
  }

  load(e) {
    e.preventDefault();
    if (e.target.checked) {
      this.fromContainerTarget.innerHTML = this.fromTemplateTarget.innerHTML
    } else {
      var parent = this.fromContainerTarget;
      parent.removeChild(parent.lastElementChild);
    }
  }
}
