import { Controller } from "@hotwired/stimulus"
import flatpickr from 'flatpickr';
import rangePlugin from "flatpickr/dist/plugins/rangePlugin"
import { AlgerianArabic }  from "flatpickr/dist/l10n/ar-dz.js"
import { english }  from "flatpickr/dist/l10n/default.js"
import { French }  from "flatpickr/dist/l10n/fr.js"
import { Mandarin }  from "flatpickr/dist/l10n/zh.js"
import { Spanish }  from "flatpickr/dist/l10n/es.js"
import { Portuguese }  from "flatpickr/dist/l10n/pt.js"
import { German }  from "flatpickr/dist/l10n/de.js"
import { Japanese }  from "flatpickr/dist/l10n/ja.js"
import { Italian }  from "flatpickr/dist/l10n/it.js"
import { Turkish }  from "flatpickr/dist/l10n/tr.js"

// :ar, :en, :fr, :cn, :es, :pt, :de, :ja, :it, :tr

// Connects to data-controller="availability"
export default class extends Controller {
  static targets = [
    "checkin", "checkout", "checkinPicker", "checkoutPicker",
    "submit",
    "preview", "nightCount", "subTotal", "total", "output",
    "clearCalendarBtn", "mobileFlatpickr"
  ]

  static values = {
    locale: String,
    bookable: String,
    price: Number,
    currency: String,
    enabled: String,
    disabled: Array,
    inline: Boolean,
    mode: String,
    showmonths: Number,
    total: String
  }

  connect() {
    this.submitBtn()
    this.bookingFees(null, this.bookableValue)
    if(this.hasCheckinPickerTarget) {
      this.initDatePicker()
    }

    // if(this.hasDisabledValue){
    //   this.getDisabledDates(null, this.bookableValue)
    // }

    // if(this.bookableValue === "Activity"){
    //   this.bookingFees(null, "Activity")
    // }
  }

  initDatePicker(){
    let controller = this;
    let calendar;
    //disable: this.getDisabledDates(null, this.bookableValue),
    let config = {
      "locale": controller.getLocale(this.localeValue),
      showMonths: controller.showmonthsValue, 
      dateFormat: controller.getdateFormat(this.localeValue),
      mode: controller.modeValue,
      inline: controller.inlineValue,
      disableMobile: true,
      minDate: "today",
      nextArrow: "<i class='fi-chevrons-right fs-7'></i>",
      prevArrow: "<i class='fi-chevrons-left fs-7'></i>",
      disable: this.getDisabledDates(null, this.bookableValue),
      "plugins": this.getPlugins(controller.modeValue, controller),
      onChange: function(selectedDates, dateStr, instance) {
        controller.submitBtn()
      },
      onReady: function(selectedDates, dateStr, instance) {
        if (selectedDates.length > 1) {
          controller.bookingFees(selectedDates, controller.bookableValue)
        }
        if (selectedDates.length === 1) {
          controller.bookingFees(selectedDates, controller.bookableValue)
        }
      },
      onClose: function(selectedDates, dateStr, instance) {
        if (selectedDates.length > 1) {
          controller.checkinTarget.value = selectedDates[0].toString()
          controller.checkoutTarget.value = selectedDates[1].toString()
          controller.bookingFees(selectedDates, controller.bookableValue)
        }
        if (selectedDates.length === 1) {
          controller.checkinTarget.value = selectedDates[0].toString()
          controller.bookingFees(selectedDates, controller.bookableValue)
        }
      },
      onDayCreate: function(dObj, dStr, fp, dayElem){
        // if (controller.hasEnabledValue) {
          
        //   //console.log(controller.enabledDates())
        //   if(controller.enabledDates().includes(`${dayElem.dateObj.getFullYear()}-${dayElem.dateObj.getMonth()+1}-${dayElem.dateObj.getDate()}`)){
        //     dayElem.className += " flatpickr-disabled flatpickr-disabled"
        //   }
        // }
      }
    }

    if (controller.hasMobileFlatpickrTarget) {
      config["appendTo"] = controller.mobileFlatpickrTarget
    }

    calendar = flatpickr(this.checkinPickerTarget, config)

    if (this.hasEnabledValue) {
      let enabledDates = this.getEnabledDates(null, this.bookableValue)
      calendar.set("enable", enabledDates)
    }
    
    controller.clearCalendarBtnTarget.addEventListener("click", (e) => {
      controller.clearCalendar(calendar)
    })
    
  }

  submitBtn() {
    if(this.modeValue === "range") {
      if(this.hasSubmitTarget && this.hasCheckinTarget && this.hasCheckoutTarget){
        if ( this.checkinTarget.value == "" || this.checkoutTarget.value == "") {
          this.submitTarget.disabled = true
        } else {
          this.submitTarget.disabled = false
        }
      }
    }
    if(this.modeValue === "single") {
      if(this.hasSubmitTarget && this.hasCheckinTarget){
        if ( this.checkinTarget.value == "") {
          this.submitTarget.disabled = true
        } else {
          this.submitTarget.disabled = false
        }
      }
    }
  }

  clearCalendar(calendar) {
    calendar.clear();
    if(this.hasCheckinTarget){
      this.checkinTarget.value = ""
    }
    if(this.hasCheckoutTarget){
      this.checkoutTarget.value = ""
    }
    this.submitBtn()
    this.bookingFees(null, this.bookableValue)
  }

  getLocale(locale) {
    // :ar, :en, :fr, :cn, :es, :pt, :de, :ja, :it, :tr
    if (locale == "ar") {
      return AlgerianArabic
    }

    if (locale == "en") {
      return english
    }

    if (locale == "fr") {
      return French
    }

    if (locale == "cn") {
      return Mandarin
    }

    if (locale == "es") {
      return Spanish
    }

    if (locale == "pt") {
      return Portuguese
    }

    if (locale == "de") {
      return German
    }

    if (locale == "ja") {
      return Japanese
    }

    if (locale == "it") {
      return Italian
    }

    if (locale == "tr") {
      return Turkish
    }

  }

  getdateFormat(locale) {
    if (locale == "ar") {
      return 'j F'
    }

    if (locale == "en") {
      return 'M j'
    }

    if (locale == "fr") {
      return 'j M. '
    }
    
    if (locale == "cn") {
      return 'M j'
    }

    if (locale == "es") {
      return 'M j'
    }

    if (locale == "pt") {
      return 'M j'
    }

    if (locale == "de") {
      return 'M j'
    }

    if (locale == "ja") {
      return 'M j'
    }

    if (locale == "it") {
      return 'M j'
    }

    if (locale == "tr") {
      return 'M j'
    }
  }
  
  getPlugins(mode, controller){
    let plugins = []
    if(mode === "range"){
      plugins.push(new rangePlugin({ input: controller.checkoutPickerTarget}))
    }
    return plugins
  }

  bookingFees(selectedDates = null, bookable = null) {
    if(bookable === "Listing"){
      return new ListingFees(selectedDates, this).listingFees()
    }

    if(bookable === "Activity"){
      return new ActivityFees(selectedDates, this).activityFees()
    }
    
  }

  getDisabledDates(selectedDates = null, bookable = null){
    if(bookable === "Listing"){
      return new ListingFees(selectedDates, this).disabledDates()
    }

    if(bookable === "Activity"){
      return new ActivityFees(selectedDates, this).disabledDates()
    }
  }

  getEnabledDates(selectedDates = null, bookable = null){
    if(bookable === "Listing"){
      return new ListingFees(selectedDates, this).enabledDates()
    }

    if(bookable === "Activity"){
      return new ActivityFees(selectedDates, this).enabledDates()
    }
  }
  
  bookingFeesBtn(){
    this.bookingFees(null, "Activity")
  }

  extraFeesBtn(){
    this.bookingFees(null, "Activity")
  }

  enabledDates(){
    let dates = []
    this.disabledValue.forEach(date => {
      dates.push(date['date'])
    })
    return dates
  }
}


class ListingFees {
  constructor(selectedDates, context) {
    this.context = context
    this.selectedDates = selectedDates;
  }

  listingFees(){
    if(this.context.hasPreviewTarget){
      if (this.selectedDates === null) {
        return this.context.previewTarget.style.display = "none"
      }else{
        let time = this.selectedDates[1].getTime() - this.selectedDates[0].getTime();
        let msInDay = 1000 * 3600 * 24;
        let nights = time / msInDay;
        let subtotal = nights * parseFloat(this.context.priceValue)
        let total = nights * parseFloat(this.context.priceValue)
  
        this.context.previewTarget.style.display = "block"
        this.context.nightCountTarget.innerHTML = `${nights} `
        this.context.subTotalTarget.innerHTML = `${subtotal.toFixed(2)} ${this.context.currencyValue}`
        this.context.totalTarget.innerHTML = `${total.toFixed(2)} ${this.context.currencyValue}`
      }
    }
  }

  disabledDates(){
    let dates = []
    this.context.disabledValue.forEach(date => {
      dates.push({
        "from": new Date(date['from']).toISOString(),
        "to": new Date(date['to']).toISOString()
      })
    })
    return dates
  }

  enabledDates(){
    
  }
}

class ActivityFees {
  tagkey
  tagname
  constructor(selectedDates, context) {
    this.context = context
    this.selectedDates = selectedDates;
  }

  activityFees(){
    this.previewInvoice()
  }

  getGuests(){
    var guestsData = {};
    var guests = this.context.element.querySelectorAll('[data-availability="guests"]');
    var guests_count = 0
    var total_price = 0
    guests.forEach(guest => {
      let type = guest.dataset.guestType
      let price = guest.dataset.guestPrice
      let value = guest.value
      var g = {}
      g["price"] = price;
      g["count"] = value;
      g["total"] = parseFloat(price) * parseInt(value);
      guests_count += parseInt(value);
      total_price += parseFloat(price) * parseInt(value)

      guestsData[type] = g
    })
    guestsData["guests"] = guests_count
    guestsData["total_price"] = total_price
    
    return guestsData
  }

  async previewGuests(){
    var guests = this.getGuests()
    var html = ``
    for (const [key, value] of Object.entries(guests)) {
      if(typeof value === 'object'){
        if(value['count'] > 0){
          this.tagkey = key
          await this.fetchTagName().then(name => {
            this.tagname = name['name']
            return
          });
          html = html + `
            <div class="d-flex justify-content-between"> 
              <div class="d-flex align-items-center">
                <span class="fs-sm">${parseFloat(value['price']).toFixed(2)}</span>
                <span class="mb-n1fs-sm"><i class="bi bi-x fs-lg"></i></span>
                <span class="fs-sm">(${value['count']} ${this.tagname.toLowerCase()})</span>
              </div>
              <b class="fs-sm">${parseFloat(value["total"]).toFixed(2)} ${this.context.currencyValue}</b>
            </div>
          `
        }
        
      }
    }
    return [html, guests["total_price"], guests["guests"] ]
  }

  getExtra(guests){
    var extraData = {}
    var extra = this.context.element.querySelectorAll('[data-availability="extra"]');
    var total_price = 0
    extra.forEach(extra => {
      if(extra.checked){
        let type = extra.dataset.extraType
        let price = extra.dataset.extraPrice
        var g = {}
        g["price"] = price;
        g["total"] = parseFloat(price) * parseInt(guests);
        
        total_price += parseFloat(price) * parseInt(guests);

        extraData[type] = g
      }
    })
    extraData["total_price"] = total_price
    return extraData
  }

  async previewExtra(){
    var guests = this.getGuests()
    var extra = this.getExtra(guests["guests"])

    var html = ``
    for (const [key, value] of Object.entries(extra)) {
      if(typeof value === 'object'){
        this.tagkey = key
        await this.fetchTagName().then(name => {
          this.tagname = name['name']
          return
        });
        html = html + `
          <div class="d-flex justify-content-between"> 
            <div class="d-flex align-items-center">
              <span class="fs-sm">${parseFloat(value['price']).toFixed(2)}</span>
              <span class="mb-n1fs-sm"><i class="bi bi-x fs-lg"></i></span>
              <span class="fs-sm">(${guests["guests"]} ${this.tagname.toLowerCase()})</span>
            </div>
            <b class="fs-sm">${parseFloat(value["total"]).toFixed(2)} ${this.context.currencyValue}</b>
          </div>
        `
      }
    }
    return [html, extra["total_price"] ]
  }

  async previewInvoice(){
    var guests = await this.previewGuests()
    var extra = await this.previewExtra()
    var total = "Total"
    if (this.context.hasTotalValue) {
      total = this.context.totalValue
    }
    var html
    html = guests[0] + extra[0] + `<hr class="m-2">
        <h5 class="d-flex justify-content-between m-0">
          <span >${total}</span>
          <span data-availability-target="total">${parseFloat(guests[1] + extra[1]).toFixed(2)} ${this.context.currencyValue}</span>
        </h5>` 

    
    if(this.context.hasPreviewTarget){
      this.context.previewTarget.style.display = "block"
      this.context.previewTarget.innerHTML = html
    }
  }

  async fetchTagName() {
    const response = await fetch(`/${this.context.localeValue}/price/tag?name=${this.tagkey}`);
    const name = await response.json();
    return name;
  }

  disabledDates(){
    let dates = []
    this.context.disabledValue.forEach(date => {
      dates.push(date['date'])
    })
    return dates
  }

  enabledDates(){
    const event = JSON.parse(this.context.enabledValue);
    let dates = []
    if(event['dates'] === ""){
      const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const eventdays = event['every'].filter(item => item !== '')
      let string = ""
      eventdays.forEach((day, i) => {
        weekdays.forEach((wday, j) => {              
          if(day == wday){
            string = string + `date.getDay() === ${j} || `
          }
        })
      })
      dates = [ 
        function(date) {     
          return eval(string.slice(0, -3));
        }
      ]

    } else {
      event['dates'].forEach(date => {
        dates.push(new Date(date).toISOString())
      })
    }
    return dates
  }
}


