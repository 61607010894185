import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="comment"
export default class extends Controller {

  static targets = [
    "comment"
  ]

  static values = {
    scrollto: String,
    user: String
  }

  connect() {  
    
  }

  commentTargetConnected(element) {
    
    let controller = this
    const commentMenu = element.querySelector('#commentMenu')
    if (typeof(commentMenu) != 'undefined' && commentMenu != null) {
      const helpers = document.querySelector('#helpers')

      if ( helpers != null && element.hasAttribute("data-comment-user")) {
        commentMenu.classList.remove('d-none')
        const privateItems = commentMenu.querySelectorAll('#privateItem')
        const publicItems = commentMenu.querySelectorAll('#publicItem')

        if (helpers.dataset.currentUser != element.dataset.commentUser) {
          privateItems.forEach((item) => {
            item.remove()
          });
        } else {
          publicItems.forEach((item) => {
            item.remove()
          });
        }
        
      } else {
        commentMenu.remove()
      }
    } 
  }

  hideReplys(){
    let controller = this
    controller.element.remove()
    var comment = document.getElementById(controller.scrolltoValue);
    console.log(controller.scrolltoValue)
    //comment.scrollIntoView({behavior: 'smooth'}, true);
  }
}
