import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="gallery"
export default class extends Controller {
  static targets = [ "preview"]
  static values = {
    xpg: String,
  }

  connect() {
    //console.log("gallery controller")
  }

  params() {
    let params = new URLSearchParams()
    params.append('gid', this.xpgValue)
    return params
  }
  
  async fetchAttachments(index) {
    let controller = this
    try {
      const response = await fetch(`/attachments?${this.params()}`, {
        headers: {  
          Accept: "application/json"  
        } 
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const attachmentsData = await response.json();
      this.renderPreview(attachmentsData)

      const container = document.getElementById('gallery-preview-container');

      
      if (container) {
        const target = document.getElementById(`t_${index}`);
        target.scrollIntoView({ behavior: 'smooth', block: 'start', top: '20' });
      }
    } catch (error) {
      console.error('Error fetching attachments:', error);
    }
  }

  handlePreviewBtn(e) {
    let controller = this
    if (controller.hasXpgValue && controller.xpgValue !== "") {
      controller.fetchAttachments(e.target.id)
    }
  }

  handleCloseBtn() {
    let controller = this
    const container = document.getElementById('gallery-preview-container');
    if (container) {
      container.remove()
    }
  }

  closeBtn() {
    const closeBtn = document.createElement('button');
    closeBtn.setAttribute("data-action", "click->gallery#handleCloseBtn");
    closeBtn.setAttribute("id", "close-btn");
    closeBtn.classList.add('btn-close', 'bg-light', 'rounded-3', 'p-2', 'm-3', 'position-fixed', 'top-0', 'zindex-1')  

    return closeBtn
  }

  renderPreview(attachmentsData) { 
    let controller = this
    const modal = document.createElement('div');
    const modalContent = document.createElement('div');
    const modalBody = document.createElement('div');
    const container = document.createElement('div');

    modal.classList.add('gallery-preview-container')
    modal.setAttribute("id", 'gallery-preview-container');
    modal.setAttribute("data-controller", "gallery");
    
    modalContent.classList.add('position-relative')  
    if (attachmentsData.attachments.length == 1) {
      modalContent.classList.add("h-100", "d-flex", "align-items-center") 
    } 

    modalBody.classList.add('col-11', 'col-md-8', 'mx-auto', 'p-2', 'text-center')

    container.classList.add("text-center", "mb-3");

    attachmentsData.attachments.forEach(function(element) {
      switch (element.content_type) {
        case 'video':
          container.appendChild(new Video(controller, element).createElement());
          break;

        case 'image':
          container.appendChild(new Image(controller, element).createElement());
          break;

        default:
          console.log('Default case');
      }
    });


    modalBody.appendChild(container)
    
    modalContent.appendChild(this.closeBtn())
    modalContent.appendChild(modalBody)

    modal.appendChild(modalContent)
    document.body.appendChild(modal)
    
  }
}

class Video {
  constructor(context, data) {
    this.context = context
    this.data = data;
  }

  createElement() {
    let controller = this.context
    const container = document.createElement('div');
    container.classList.add('text-center', 'rounded-1', 'h-100', 'w-100')
    container.style = `max-height: 87vh; aspect-ratio: ${this.data.blob.metadata.width}/${this.data.blob.metadata.height}`
    container.setAttribute("id", `t_${this.data.blob.id}`);

    const video = document.createElement('video');
    video.classList.add('h-100', 'w-100', 'my-3')

    video.style = `max-width: 100%; max-height: 100%;`
    video.src = `${this.data.url}#t=0.5`;
    video.controls = true;
    video.autoplay = false;
    video.loop = false;
    video.preload = 'auto'
    video.volume = 0
    video.pause();
    
    var observer = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          // Play the video when it comes into the viewport
          video.volume = 1
          video.play();
        } else {
          // Pause the video when it goes out of the viewport
          video.volume = 0
          video.pause();
        }
      });
    }, { threshold: 1 });
    observer.observe(video);

    container.appendChild(video);
    return container
  }
}

class Image {
  constructor(context, data) {
    this.context = context
    this.data = data;
  }

  createElement() {
    let controller = this.context
    const container = document.createElement('div');
    container.classList.add('text-center', 'rounded-1', 'h-100', 'w-100')
    container.style = `max-height: 87vh; aspect-ratio: ${this.data.blob.metadata.width}/${this.data.blob.metadata.height}`
    container.setAttribute("id", `t_${this.data.blob.id}`);

    const image = document.createElement('img');
    image.classList.add('rounded-1', 'img-fluid', 'h-100', 'object-fit-cover', 'my-3')
    image.src = this.data.url;

    container.appendChild(image);
    return container
  }
}

class ClickAndHold {
  constructor(target, timeout, StartCallback, EndCallback) {
    this.target = target;
    this.timeout = timeout;
    this.StartCallback = StartCallback;
    this.EndCallback = EndCallback;
    this.isHeld = false;
    this.activeHoldTimeoutId = null;

    ["mousedown", "touchstart"].forEach(eventType => {
      this.target.addEventListener(eventType, this._onTouchStart.bind(this), false)
    });

    ["mouseup",  "touchend" ].forEach(eventType => {
      this.target.addEventListener(eventType, this._onTouchEnd.bind(this), false)
    });
  }

  _onTouchStart(e) {
    clearTimeout(this.activeHoldTimeoutId);
    this.activeHoldTimeoutId = setTimeout(() => {
      console.log('hold')
      this.isHeld = true;
      if (this.isHeld) {
        this.StartCallback();
      }
    }, this.timeout);
  }

  _onTouchEnd(e) {
    clearTimeout(this.activeHoldTimeoutId);
    if (this.isHeld) {
      this.isHeld = false;
      this.EndCallback();
    }
  }
  
  static apply(target, timeout, StartCallback, EndCallback) {
    new ClickAndHold(target, timeout, StartCallback, EndCallback);
  }
}
