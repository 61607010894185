import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="validator"
export default class extends Controller {
  static targets = ["editor","counter"]

  connect() {
    console.log('validator connected')
    let controller = this
    controller.counterTarget.textContent = `${0}/${controller.maxChars}`;

    document.addEventListener('trix-change', function (event) {
      const editor = controller.editorTarget;
      const characterCount = controller.counterTarget;

      const maxLength = controller.maxChars; // Set your desired maximum character count

      const currentLength = editor.innerText.length;
      characterCount.textContent = `${currentLength}/${maxLength}`;

      if (currentLength > maxLength-1) {
        characterCount.style.color = 'red'; // Change to your preferred error color
        event.preventDefault();
      } else {
        characterCount.style.color = 'black'; // Reset color if within limit
        editor.setAttribute('contenteditable', 'true');
      }
    });
  }

  get maxChars() {
    return this.data.get("maxChars") || 1;
  }
}
