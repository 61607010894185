import { Controller } from "@hotwired/stimulus"
import { tns } from 'tiny-slider'
// Connects to data-controller="carousel"
export default class extends Controller {
	static values = {
		page: Number
	}
	
	connect() {
		// forEach function
		var forEach = function forEach(array, callback, scope) {
			for (var i = 0; i < array.length; i++) {
				callback.call(scope, i, array[i]); // passes back stuff we need
			}
		}; // Carousel initialisation


		var carousels = document.querySelectorAll('#items-page-'+ this.pageValue +' .tns-carousel-inner');
		forEach(carousels, function (index, value) {
			var controlsText;

			if (value.dataset.carouselOptions != undefined && JSON.parse(value.dataset.carouselOptions).axis === 'vertical') {
				controlsText = ['<i class="fi-chevron-up"></i>', '<i class="fi-chevron-down"></i>'];
			} else {
				controlsText = ['<i class="fi-chevron-left"></i>', '<i class="fi-chevron-right"></i>'];
			}

			var defaults = {
				container: value,
				controlsText: controlsText,
				navPosition: 'bottom',
				mouseDrag: true,
				speed: 500,
				autoplay: false,
				autoplayHoverPause: true,
				autoplayButtonOutput: false,
			};
			var userOptions;
			if (value.dataset.carouselOptions != undefined) userOptions = JSON.parse(value.dataset.carouselOptions);
			var options = Object.assign({}, defaults, userOptions);
			var carousel = tns(options);
			var carouselWrapper = value.closest('.tns-carousel-wrapper'),
				carouselItems = carouselWrapper.querySelectorAll('.tns-item'),
				carouselInfo = carousel.getInfo(),
				carouselCurrentSlide = carouselWrapper.querySelector('.tns-current-slide'),
				carouselTotalSlides = carouselWrapper.querySelector('.tns-total-slides'); // Center slide

			if (carouselWrapper.classList.contains('tns-center')) {
				var indexCurrentInitial = carouselInfo.index;
				carouselInfo.slideItems[indexCurrentInitial].classList.add('active');
				carousel.events.on('indexChanged', function () {
					var info = carousel.getInfo(),
						indexPrev = info.indexCached,
						indexCurrent = info.index;
					info.slideItems[indexPrev].classList.remove('active');
					info.slideItems[indexCurrent].classList.add('active');
				});
			} // Slides count


			if (carouselWrapper.querySelector('.tns-slides-count') === null) return;
			carouselCurrentSlide.innerHTML = carouselInfo.displayIndex;
			carouselTotalSlides.innerHTML = carouselInfo.slideCount;
			carousel.events.on('indexChanged', function () {
				var info = carousel.getInfo();
				carouselCurrentSlide.innerHTML = info.displayIndex;
			});
		});
	}
}
