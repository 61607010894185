import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="invoice"
export default class extends Controller {
  static targets = [
    "guestInput"
  ]
  connect() {
    let addguests = document.getElementsByClassName("addguests");
    let removeguests = document.getElementsByClassName("removeguests");

    for(let i = 0; i < addguests.length; i++) {
      addguests[i].addEventListener("click", e => {
        e.preventDefault();
        this.guestInputTarget.value = ++this.guestInputTarget.value
      })
    }

    for(let i = 0; i < removeguests.length; i++) {
      removeguests[i].addEventListener("click", e => {
        e.preventDefault();
        this.guestInputTarget.value = --this.guestInputTarget.value
      })
    }
  }
}
