import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

let tooltip
// Connects to data-controller="tooltip"
export default class extends Controller {
  static targets = ["button"]
 
  connect() {
    const tooltip = new bootstrap.Tooltip(this.buttonTarget)
    //console.log("connected")
  }

  disconnect() { 
    const tooltip = bootstrap.Tooltip.getOrCreateInstance(this.buttonTarget)
    tooltip.hide()
    //console.log("disconnected")
  }
}
