import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="see-more-less"
export default class extends Controller {
  static targets = [ "content"]
  static values = {
    more: String,
    less: String,
  }
  initialize() {
    
  }

  toggleContent(e) {
    let _this = this
    let lineClampClass = ""
    e.preventDefault();
    if (_this.contentTarget.classList.contains('article')) {
      _this.contentTarget.classList.toggle('line-clamp-3')
      lineClampClass = "line-clamp-3"
    } else {
      _this.contentTarget.classList.toggle('line-clamp-4')
      lineClampClass = "line-clamp-4"
    }
    
    _this.contentTarget.classList.toggle('line-clamp-50')

    if (_this.contentTarget.classList.contains(lineClampClass)) { 
      e.target.classList.add('more')
      e.target.classList.remove('less')
      e.target.innerHTML = _this.moreValue;
    } else {      
      e.target.classList.add('less')
      e.target.classList.remove('more')
      e.target.innerHTML = _this.lessValue;
    }
  }
}
