import { Controller } from "@hotwired/stimulus"
import Dropzone from "dropzone"
import { DirectUpload } from "@rails/activestorage"
import {
  getMetaValue,
  toArray,
  findElement,
  removeElement,
  insertAfter
} from "../helpers";

Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console

export default class extends Controller {
  static targets = [
    "input", 
    "previewTemplate",
    "previewContainer",
    "uploaderBtn"
  ];

  static values = {
    submitDisabled: Boolean,
    gridClasses: Array
  }

  connect() {
    let controller = this
    this.dropZone = createDropZone(this);
    this.hideFileInput(); 
    if (this.hasSubmitDisabledValue) {
      this.submitBtnState(this.submitDisabledValue); // true/false is the submit disabled state
    } else {
      this.submitBtnState(true); // true/false is the submit disabled state
    }
    this.adjustLayout();
    this.bindEvents();
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  submitBtnState(state) {
    const parentForm = this.element.closest('form:not(.button_to)');
    const submitBtn = parentForm.querySelector('input[type="submit"]')
    if (submitBtn) {
      submitBtn.disabled = state;
      if (typeof submitBtn.dataset.labeled !== 'undefined') {
        let labeled = submitBtn.dataset.labeled === 'true';
        if (labeled) {
          if (document.querySelector(`button[id="${submitBtn.id}"]`)) {
            document.querySelector(`button[id="${submitBtn.id}"]`).disabled = state;
          }
        } else {
          console.log(submitBtn.dataset.labeled);
        }
        
      }
    }
  }

  adjustLayout() {    
    if (this.hasPreviewContainerTarget && this.previewContainerTarget.querySelectorAll('.preview-item').length <= 1) {
      this.previewContainerTarget.classList.remove(...this.gridClassesValue)
    } else {
      this.previewContainerTarget.classList.add(...this.gridClassesValue)
    }
  }

  bindEvents() {
    let controller = this
    let uploaderBtn = null;

    if (controller.hasUploaderBtnTarget) {
      uploaderBtn = controller.uploaderBtnTarget;
    }
    
    this.dropZone.on("addedfile", file => {
      controller.submitBtnState(true);
      if (uploaderBtn !== null) {
        uploaderBtn.remove();        
      }
      
      controller.previewContainerTarget.classList.add(...this.gridClassesValue)
      setTimeout(() => {
        file.previewElement.querySelector('[data-dz-name]').textContent = file.upload.filename;
        file.accepted && createDirectUploadController(this, file).start();
      }, 500);
      
      if (uploaderBtn !== null) {
        controller.previewContainerTarget.appendChild(uploaderBtn);       
      }
    });

    this.dropZone.on("removedfile", file => {
      file.controller && removeElement(file.controller.hiddenInput);
      
      if(controller.dropZone.files.length < 1 || !controller.dropzonzStatus() ) {
        controller.submitBtnState(true);
      } else {
        controller.submitBtnState(false);
      }

      controller.adjustLayout();
    });
    
    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort();
    }); 

    this.dropZone.on("queuecomplete", function (file) { 
      controller.adjustLayout();
      if (controller.dropzonzStatus()) {
        controller.submitBtnState(false);        
      }
    });
  }

  dropzonzStatus() {
    let status = true
    this.dropZone.files.forEach(function(file) {
      if (!file.accepted) {
        status = false;
        return;
      }
    });

    return status;
  }

  textEditor() {
    return this.data.get("textEditor") === 'true';
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }

  get dictRemoveFile() {
    return this.data.get("dictRemoveFile");
  }

  get dictCancelUpload() {
    return this.data.get("dictCancelUpload");
  }

  get previewsContainer() {
    return this.data.get("previewsContainer") || null;
  }

  
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    renameFile: renameFile,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    dictRemoveFile: controller.dictRemoveFile,
    dictCancelUpload: controller.dictCancelUpload,
    previewsContainer: controller.previewsContainer,
    autoQueue: false,
    previewTemplate: controller.previewTemplateTarget.innerHTML,
    capture: null,
    thumbnailWidth: 300,
    thumbnailHeight: 300
  });
}

function renameFile(file) {
  const timestamp = Date.now();
  const extension = file.name.split('.').pop();
  file.name = `${timestamp}_sw.${extension}`
  return `${timestamp}_sw.${extension}`;
}