import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="activity-theme"
export default class extends Controller {
  static targets = [
    "theme",
    "subTheme",
  ]

  static values = {
    url: String,
    selected: String,
    subselected: String,
  }

  connect() {
    this.subThemeTarget.disabled = true
    if (this.selectValue().length > 0) {
      this.fetch()
    }
  }

  selectValue() {
    return this.themeTarget.selectedOptions[0].value
  }

  fetch() {
    if (this.selectValue().length > 0) {      
      this.subThemeTarget.disabled = false
    }else {
      while (this.subThemeTarget.options.length > 0) {                
        this.subThemeTarget.remove(0);
      }
      this.subThemeTarget.disabled = true
    }
    fetch(`${this.urlValue}?${this.params()}`, {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    }).then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

  params() {
    let params = new URLSearchParams()
    params.append('id', this.selectValue())
    params.append('select', this.subThemeTarget.id)
    params.append('selected', this.selectedValue)
    params.append('subselected', this.subselectedValue)
    return params
  }
}
