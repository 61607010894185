import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-reset"
export default class extends Controller {

  static targets = [
    "previewContainer",
    "input"
  ];

  static values = {
    enterbtn: Boolean,
  }

  connect() {
    let controller = this;
    
    if (controller.hasEnterbtnValue) {
      document.addEventListener('keydown', function(event) {
        if (event.key === 'Enter') {
          event.preventDefault();                 // Prevent default Enter key action
          controller.element.submit();            // Submit the form
          controller.reset()
        } else if (event.key === 'Escape') {
          event.preventDefault();                 // Prevent default Escape key action
          controller.reset()                      // Reset the form
        }
      });
    }
  }
  
  reset() {
    let controller = this;
    if (controller.hasPreviewContainerTarget) {
      controller.previewContainerTarget.innerHTML = '';
    }
    if (controller.hasInputTarget) {
      let files = Array.from(controller.inputTarget.querySelectorAll('input[type="hidden"]')).filter(input => input.value.trim() !== '')
      files.forEach(input => input.remove());
    }
    
    this.element.reset()
  }
}
