import { Controller } from "@hotwired/stimulus"
var map;
var myIcon = L.icon({
  iconUrl: '/assets/map/marker-icon-a09d7735a89827629b09f5dca1376aae251a53b4b3bf8d58065725bd26006de2.png',
  iconSize: [80],
});
var markers = []
// Connects to data-controller="leaflet-map-create"
export default class extends Controller {
  static targets = ["map", "latitude", "longitude"]
  static values = {
    message: String,
  }

  connect() {
    this.initMap()
  }

  initMap() {
    let latlng = L.latLng(36.734894269667954,3.0871133873491323);
    map = L.map(this.mapTarget, {
      center: [36.734894269667954,3.0871133873491323],
      zoom: 13,
    });
    // https://api.maptiler.com/maps/pastel/{z}/{x}/{y}.png?key=daX45qZm7YQWvcaMga6c
    L.tileLayer('https://api.maptiler.com/maps/openstreetmap/{z}/{x}/{y}.jpg?key=daX45qZm7YQWvcaMga6c', {
        maxZoom: 17,
        attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
    }).addTo(map);

    map.on('click', this.onMapClick);    
  }

  onMapClick(e) {
    map.setView(e.latlng, 20);
  }
}
