import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="social-share"
export default class extends Controller {

  static targets = [
    "copy",
    "more",
    "facebook",
    "messenger",
    "whatsapp"
  ]

  static values = {
    url: String
  }
  connect() {
    let controller = this;
    
    if (controller.hasCopyTarget && controller.hasUrlValue) {
      const copyIcon = controller.copyTarget.innerHTML
      controller.copyTarget.addEventListener('click', async (event) => {
        try {
          await navigator.clipboard.writeText(controller.urlValue);          
          controller.copyTarget.innerHTML = '<i class="bi bi-check-lg" style="font-size: 2rem;"></i>'
          controller.copyTarget.disabled = true

          setTimeout(() => {
            controller.copyTarget.innerHTML = copyIcon
            controller.copyTarget.disabled = false
          }, 2000);
          console.log('Content copied to clipboard');
        } catch (err) {
          console.error('Failed to copy: ', err);
        }
      });
    }

    if (controller.hasMoreTarget && controller.hasUrlValue) {
      controller.moreTarget.addEventListener('click', async (event) => {
        const title = document.title; 
        const url = window.location.href; // or any custom URL
        const text = ''; // or any custom text
        console.log(navigator.share)
        if (navigator.share) {
          try {
              await navigator.share({
                title: title,
                text: text,
                url: controller.urlValue,
              });
              console.log('Content shared successfully');
          } catch (error) {
              console.error('Error sharing content:', error);
          }
        } else {
          alert('Web Share API not supported in this browser.');
        }
      });
    }
    
    if (controller.hasFacebookTarget && controller.hasUrlValue) {
      controller.facebookTarget.addEventListener('click', (event) => {
        event.preventDefault();
        controller.shareOnFacebook(controller.urlValue);
      });
    }

    if (controller.hasMessengerTarget && controller.hasUrlValue) {
      controller.messengerTarget.addEventListener('click', (event) => {
        event.preventDefault();
        controller.shareOnMessenger(controller.urlValue);
      });
    }

    if (controller.hasWhatsappTarget && controller.hasUrlValue) {
      controller.whatsappTarget.addEventListener('click', (event) => {
        event.preventDefault();
        controller.shareOnWhatsApp(controller.urlValue);
      });
    }
  }
  
  shareOnFacebook(url) {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)} `;
    window.open(facebookShareUrl, '_blank' );
  }

  shareOnMessenger(url) {
    const messengerShareUrl = `fb-messenger://share/?link=${encodeURIComponent(url)} `;
    window.open(messengerShareUrl, '_blank');
  }

  shareOnWhatsApp(url) {
    const whatsappShareUrl = `whatsapp://send?text=${encodeURIComponent(url)} `;
    window.open(whatsappShareUrl, '_blank');
  }

  shareMore(url) {
    const whatsappShareUrl = `whatsapp://send?text=${encodeURIComponent(url)} `;
    window.open(whatsappShareUrl, '_blank');
  }
}
